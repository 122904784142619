import React, { useRef } from "react";
import ProjectImg from "./components/ProjectImg/projectImg";
import ProjectCard from "./components/ProjectCard/projectCard";
import ComputerStackHero from "./components/ComputerStackHero/ComputerStackHero";
import p from "./projects.json";

function Portfolio() {
  const projectsRef = useRef(null);

  // Scroll to the projects function
  const scrollToProjects = () => {
    if (projectsRef.current) {
      projectsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <ComputerStackHero scrollToProjects={scrollToProjects} />
      <div ref={projectsRef} className="projects-plp-grid">
        {/* PROJECT 1 : ANDROID */}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["android"].images[1].src}
            imgAlt={p["android"].images[1].alt}
          />
        </div>
        <div
          className="projects-plp-grid__card"
          style={{ "--force-row-index": "1" }}
        >
          <ProjectCard
            imgSrc={p["android"].images[0].src}
            imgAlt={p["android"].images[0].alt}
            iconSrc={p["android"].icon}
            title={p["android"].title}
            ctas={p["android"].ctas}
          >
            {p["android"].description}
          </ProjectCard>
        </div>
        {/* PROJECT 2: SURVEY */}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["survey"].images[2].src}
            imgAlt={p["survey"].images[2].alt}
          />
        </div>
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["survey"].images[1].src}
            imgAlt={p["survey"].images[1].alt}
          />
        </div>
        <div
          className="projects-plp-grid__card"
          style={{ "--force-row-index": "3" }}
        >
          <ProjectCard
            imgSrc={p["survey"].images[0].src}
            imgAlt={p["survey"].images[0].alt}
            iconSrc={p["survey"].icon}
            title={p["survey"].title}
            ctas={p["survey"].ctas}
          >
            {p["survey"].description}
          </ProjectCard>
        </div>
        {/* PROJECT 3 : FAMILIES SHARE*/}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["families"].images[1].src}
            imgAlt={p["families"].images[1].alt}
          />
        </div>
        <div className="projects-plp-grid__card">
          <ProjectCard
            imgSrc={p["families"].images[0].src}
            imgAlt={p["families"].images[0].alt}
            iconSrc={p["families"].icon}
            title={p["families"].title}
            ctas={p["families"].ctas}
          >
            {p["families"].description}
          </ProjectCard>
        </div>
        {/* PROJECT 4: CHESS */}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["chess"].images[2].src}
            imgAlt={p["chess"].images[2].alt}
          />
        </div>
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["chess"].images[1].src}
            imgAlt={p["chess"].images[1].alt}
          />
        </div>
        <div
          className="projects-plp-grid__card"
          style={{ "--force-row-index": "7" }}
        >
          <ProjectCard
            imgSrc={p["chess"].images[0].src}
            imgAlt={p["chess"].images[0].alt}
            iconSrc={p["chess"].icon}
            title={p["chess"].title}
            ctas={p["chess"].ctas}
          >
            {p["chess"].description}
          </ProjectCard>
        </div>
        {/* END OF CYCLE */}
        {/* PROJECT 5 : BATTLESHIP */}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["battleship"].images[1].src}
            imgAlt={p["battleship"].images[1].alt}
          />
        </div>
        <div
          className="projects-plp-grid__card"
          style={{ "--force-row-index": "9" }}
        >
          <ProjectCard
            imgSrc={p["battleship"].images[0].src}
            imgAlt={p["battleship"].images[0].alt}
            iconSrc={p["battleship"].icon}
            title={p["battleship"].title}
            ctas={p["battleship"].ctas}
          >
            {p["battleship"].description}
          </ProjectCard>
        </div>
        {/* PROJECT 6: LOROPIANA */}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["loropiana"].images[2].src}
            imgAlt={p["loropiana"].images[2].alt}
          />
        </div>
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["loropiana"].images[1].src}
            imgAlt={p["loropiana"].images[1].alt}
          />
        </div>
        <div
          className="projects-plp-grid__card"
          style={{ "--force-row-index": "11" }}
        >
          <ProjectCard
            imgSrc={p["loropiana"].images[0].src}
            imgAlt={p["loropiana"].images[0].alt}
            iconSrc={p["loropiana"].icon}
            title={p["loropiana"].title}
            ctas={p["loropiana"].ctas}
          >
            {p["loropiana"].description}
          </ProjectCard>
        </div>
        {/* PROJECT 7 : PLACEHOLDER */}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc="/images/placeholder.png"
            imgAlt="placeholderimg"
          />
        </div>
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc="/images/placeholder.png"
            imgAlt="placeholderimg"
          />
        </div>
        {/* PROJECT 8 : PLACEHOLDER */}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc="/images/placeholder.png"
            imgAlt="placeholderimg"
          />
        </div>
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc="/images/placeholder.png"
            imgAlt="placeholderimg"
          />
        </div>
        <div
          className="projects-plp-grid__card"
          style={{ "--force-row-index": "15" }}
        >
          <ProjectImg
            imgSrc="/images/placeholder.png"
            imgAlt="placeholderimg"
          />
        </div>
        {/* END OF CYCLE */}
      </div>
    </>
  );
}

export default Portfolio;
