import React from "react";
import { useParams } from "react-router-dom";
import p from "../projects.json";
import { Row, Col, Button, Image } from "react-bootstrap";
import DownloadFile from "../../../common/downloadFile/DownloadFile";

function Project() {
  const { projectName } = useParams();

  return (
    <>
      <Row>
        <Col sm={12} md={6} lg={8}>
          <Row>
            {p[projectName].images.map((img) => (
              <Col sm={12} lg={6} className="p-2">
                <Image
                  src={img.src}
                  alt={img.alt}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col sm={12} md={6} lg={4} className="project-description">
          <div className="project-description__content">
            <div className="d-flex justify-content-between align-items-start">
              <h1 className="m-0">{p[projectName].title}</h1>
              <Image
                className="project-description__content--image"
                src={p[projectName].icon}
                alt="projIcon"
              />
            </div>
            <p className="mt-4">{p[projectName].description}</p>
            {p[projectName].doc && (
              <div className="mb-7">
                <DownloadFile filePath={p[projectName].doc} />
              </div>
            )}
            {p[projectName].ctas.map(
              (cta, index) =>
                cta.text !== "View details" && (
                  <Button
                    key={index}
                    className="w-100 my-2"
                    variant={cta.color}
                    href={cta.url}
                  >
                    {cta.text}
                  </Button>
                )
            )}
            <Button
              key="gitRepo"
              className="w-100 my-2"
              variant="accent2"
              href={p[projectName].gitUrl}
            >
              Git Repo
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Project;
