import React, { useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Stats, OrbitControls, Float } from "@react-three/drei";
import { Button } from "react-bootstrap";
import { useControls } from "leva";

import LightHelper from "../../../models/LightHelper";
import Cube from "../../../models/Cube";
import Macbook from "../../../models/Macbook";
import Glasses from "../../../models/Glasses";
import Sweater from "../../../models/Sweater";
import Jeans from "../../../models/Jeans";
import Shoes from "../../../models/Shoes";
import ComputerStack from "../../../models/ComputerStack";

// Component for rendering the active model with animations
function AnimatedModel({ ModelComponent, scale, position, rotation }) {
  const modelRef = useRef();

  return (
    <group ref={modelRef}>
      {React.cloneElement(ModelComponent, { scale, position, rotation })}
    </group>
  );
}

export default function ThreeJsSection() {
  // States
  const [currentModelIndex, setCurrentModelIndex] = useState(0);
  // Refs
  const lightRef = useRef();

  // Models
  const models = [
    {
      component: <Cube />,
      props: { scale: 1, position: [0, 0, 0], rotation: [0, 0, 0] },
    },
    {
      component: <Macbook />,
      props: { scale: 0.15, position: [0, -1, 0], rotation: [0, 0, 0] },
    },
    {
      component: <Glasses />,
      props: { scale: 0.01, position: [0, -0.5, 0], rotation: [0, 0, 0] },
    },
    {
      component: <Sweater />,
      props: { scale: 6, position: [0, -7, 0], rotation: [0, 0, 0] },
    },
    {
      component: <Jeans />,
      props: { scale: 3.5, position: [0, -3, 0], rotation: [0, 0, 0] },
    },
    {
      component: <Shoes />,
      props: { scale: 1, position: [0, -1, 0], rotation: [0, 0, 0] },
    },
    {
      component: <ComputerStack />,
      props: { scale: 0.4, position: [0, 0, 0], rotation: [0, 0, 0] },
    },
  ];

  // Dynamic Leva Controls for Active Model
  const activeModelProps = models[currentModelIndex].props;
  const { scale, position, rotation } = useControls(
    `Model ${currentModelIndex} Controls`,
    {
      scale: { value: activeModelProps.scale || 1, step: 0.1 },
      position: { value: activeModelProps.position || [0, 0, 0], step: 0.1 },
      rotation: { value: activeModelProps.rotation || [0, 0, 0], step: 0.1 },
    },
    [currentModelIndex]
  );

  // Handle arrow navigation
  const handleNext = () => {
    const nextIndex = (currentModelIndex + 1) % models.length;
    setCurrentModelIndex(nextIndex);
  };
  const handlePrevious = () => {
    const prevIndex = (currentModelIndex - 1 + models.length) % models.length;
    setCurrentModelIndex(prevIndex);
  };

  return (
    <div className="vh-100 position-relative m-0 m-lg-4 my-6">
      <h3 className="text-center">R3F Models</h3>
      <Canvas className="bg-dark">
        {/* Render the active model */}
        <AnimatedModel
          ModelComponent={models[currentModelIndex].component}
          scale={scale}
          position={position}
          rotation={rotation}
        />

        {/* Lights */}
        <ambientLight intensity={0.5} />
        <directionalLight
          ref={lightRef}
          position={[-5, 10, 10]}
          intensity={1}
          castShadow
        />
        {/* Optionally, you can use the drei helper for directional light */}
        <LightHelper lightRef={lightRef} />

        {/* Orbit Controls */}
        <OrbitControls />

        {/* Stats and Debug */}
        <Stats />
      </Canvas>

      {/* Navigation Controls */}
      <Button
        onClick={handlePrevious}
        key={"prev-model-button"}
        variant={"accent1"}
        className="position-absolute top-50 start-0 mx-2 rounded-circle"
        style={{
          width: "40px",
          height: "40px",
          padding: "0",
          fontSize: "20px",
        }}
      >
        &lt;
      </Button>
      <Button
        onClick={handleNext}
        key={"next-model-button"}
        variant={"accent1"}
        className="position-absolute top-50 end-0 mx-2 rounded-circle"
        style={{
          width: "40px",
          height: "40px",
          padding: "0",
          fontSize: "20px",
        }}
      >
        &gt;
      </Button>
    </div>
  );
}
