import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/common/header/header';
import Footer from './components/common/footer/footer';
import Home from './components/pages/home/home';
import Portfolio from './components/pages/portfolio/portfolio';
import Project from './components/pages/portfolio/projects/project'
import Contact from './components/pages/contact/contact';
import About from './components/pages/about/about';
import ChessDemo from './components/pages/portfolio/projects/chess/chessDemo';
import FreeBoard from './components/pages/portfolio/projects/chess/components/FreeBoard/FreeBoard';
import ComputerGame from './components/pages/portfolio/projects/chess/components/ComputerGame/ComputerGame';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Header />
        <main className="main-page-container">
          <div className='main-page-container-margin'>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/portfolio/:projectName" element={<Project />} />
              <Route path="/portfolio/chessdemo" element={<ChessDemo />} />
              <Route path="/portfolio/chessdemo/freeboard" element={<FreeBoard />} />
              <Route path="/portfolio/chessdemo/computergame" element={<ComputerGame />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </div>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
