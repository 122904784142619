import React from "react";
import { Button } from "react-bootstrap";
import { FaDownload, FaFilePdf } from "react-icons/fa";

const DownloadFile = ({ filePath }) => {
  const fileName = filePath.split("/").pop();

  return (
    <div className="d-flex p-4 bg-dark align-items-center">
      <FaFilePdf style={{ fontSize: "2rem" }} />
      <p className="mb-0 mx-2">{fileName}</p>
      <a className="ms-auto" href={filePath} download={fileName}>
        <Button variant="primary" block>
          <FaDownload />
        </Button>
      </a>
    </div>
  );
};

export default DownloadFile;
