import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import ContactForm from "./components/contactForm";
import ContactCard from "./components/contactCard";
import MacbookHero from "./macbookHero/MacbookHero";
import DownloadFile from "../../common/downloadFile/DownloadFile";

import {
  FaHome,
  FaPhone,
  FaEnvelope,
  FaLinkedin,
  FaGithub,
  FaInstagram,
} from "react-icons/fa";

function Contact() {
  const formRef = useRef(null);

  // Scroll to the form function
  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <MacbookHero scrollToForm={scrollToForm} />
      <Row ref={formRef} className="justify-content-around pt-6">
        <Col lg={6} xl={5} xxl={4} className="mb-4">
          <h2 className="mb-6">Contact Form:</h2>
          <ContactForm />
        </Col>

        <Col lg={6} xl={5} xxl={4}>
          <h2 className="mb-6">Contact Information:</h2>
          <ContactCard
            icon={FaHome}
            iconColor="primary"
            line1="Residence"
            line2="Venice, Italy"
            href="https://www.google.com/maps/place/Venice,+Metropolitan+City+of+Venice/@45.4044243,12.2221857,11z/data=!3m1!4b1!4m6!3m5!1s0x477eb1daf1d63d89:0x7ba3c6f0bd92102f!8m2!3d45.440379!4d12.3159547!16zL20vMDdfcGY?entry=ttu&g_ep=EgoyMDI0MTExNy4wIKXMDSoASAFQAw%3D%3D"
          />
          <ContactCard
            icon={FaPhone}
            iconColor="secondary"
            line1="Phone"
            line2="+39 3456129917"
            href="tel:+393456129917"
          />
          <ContactCard
            icon={FaEnvelope}
            iconColor="accent3"
            line1="Email"
            line2="stevanay@gmail.com"
            href="mailto:stevanay@gmail.com"
          />
          <ContactCard
            icon={FaLinkedin}
            iconColor="accent1"
            line1="LinkedIn"
            line2="https://www.linkedin.com/in/stevanatogiovanni"
            href="https://www.linkedin.com/in/stevanatogiovanni"
          />
          <ContactCard
            icon={FaGithub}
            iconColor="accent2"
            line1="GitHub"
            line2="https://github.com/giova239"
            href="https://github.com/giova239"
          />
          <ContactCard
            icon={FaInstagram}
            iconColor="accent4"
            line1="Instagram"
            line2="https://www.instagram.com/giobba___"
            href="https://www.instagram.com/giobba___"
          />
          <DownloadFile filePath="/pdf/CV_Giovanni_Stevanato.pdf" />
        </Col>
      </Row>
    </>
  );
}

export default Contact;
